import { Application } from '@hotwired/stimulus';

import AnalyticsCounterController from './analytics_counter_controller';
import BCAFilterTabsController from './bca_filter_tabs_controller';
import CancelButtonController from './cancel_button_controller';
import ClearSearchController from './clear_search_controller';
import CompaniesHouseFilesController from './companies_house_files_controller';
import CompanyDetailsController from './company_details_controller';
import CompanyLookupController from './company_lookup_controller';
import CreditsafeController from './creditsafe_controller';
import CompaniesHouseController from './companies_house_controller';
import FastConnectionController from './fast_connection_controller';
import IndustriesController from './industries_controller';
import ParsleyController from './parsley_controller';
import PasswordController from './password_controller';
import ProceedsPurposesController from './proceeds_purposes_controller';
import ProductInfoController from './product_info_controller';
import ScrollTopController from './scroll_top_controller';
import SelectAllController from './select_all_controller';
import SidebarController from './sidebar_controller';
import SoftValidationController from './soft_validation_controller';
import SubmitOnChangeController from './submit_on_change_controller';

window.Stimulus = Application.start();
window.Stimulus.register('analytics-counter', AnalyticsCounterController);
window.Stimulus.register('bca-filter-tabs', BCAFilterTabsController);
window.Stimulus.register('cancel-button', CancelButtonController);
window.Stimulus.register('clear-search', ClearSearchController);
window.Stimulus.register('companies-house-files', CompaniesHouseFilesController);
window.Stimulus.register('company-details', CompanyDetailsController);
window.Stimulus.register('company-lookup', CompanyLookupController);
window.Stimulus.register('creditsafe', CreditsafeController);
window.Stimulus.register('companies-house', CompaniesHouseController);
window.Stimulus.register('fast-connection', FastConnectionController);
window.Stimulus.register('industries', IndustriesController);
window.Stimulus.register('parsley', ParsleyController);
window.Stimulus.register('password', PasswordController);
window.Stimulus.register('proceeds-purposes', ProceedsPurposesController);
window.Stimulus.register('product-info', ProductInfoController);
window.Stimulus.register('scroll-top', ScrollTopController);
window.Stimulus.register('select-all', SelectAllController);
window.Stimulus.register('sidebar', SidebarController);
window.Stimulus.register('soft-validation', SoftValidationController);
window.Stimulus.register('submit-on-change', SubmitOnChangeController);

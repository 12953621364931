import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import axios from 'axios';
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';

/**
 * Example usage:
 * <div data-controller="industries">
 *   <select data-industries-target="section">
 *   <select data-industries-target="industry">
 * </div>
 */
export default class extends Controller {
  static targets = ['section', 'industry'];

  defaultOptions = { placeholder: 'Please select one option', theme: 'bootstrap-5' };

  connect() {
    const selected = this.sectionTarget.selectedIndex;

    this.industries = $(this.industryTarget).select2(this.defaultOptions);

    // Focus on the search field when opening the select box
    this.industries.on('select2:open', () => $('.select2-search__field')[0].focus());

    if (selected < 0) {
      this.industries.prop('disabled', true);
    }

    this.listenSectionChanges();
  }

  listenSectionChanges() {
    this.sectionTarget.addEventListener('change', (event) => {
      const industryId = event.target.value;

      if (!industryId) {
        this.industries.prop('disabled', true);
        return;
      }

      axios.get(`/industry_sections/${industryId}`).then((response) => {
        const { sorted_industries } = response.data;
        this.appendIndustries(sorted_industries);
      });
    });
  }

  appendIndustries(industries) {
    const data = industries.map((industry) => ({ id: industry.id, text: industry.to_s }));

    this.industries.empty().select2({ ...this.defaultOptions, data });

    // Unselect so the user can see the placeholder
    this.industries.val(null).trigger('change');
    this.industries.prop('disabled', false);
  }
}

import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

/**
 * This is the controller to fetch finance requirements when building a finance application. In the
 * form the user picks the primary use of funding and then there's another select box that needs to
 * be populated with the proceeds purposes.
 */
export default class extends Controller {
  static values = { selected: Number };
  static targets = ['overview', 'details'];

  connect() {
    let selectedOverview;

    this.overviewTargets.forEach((overview) => {
      if (overview.checked) {
        selectedOverview = overview.value;
      }

      overview.addEventListener('change', ({ target }) => {
        this.fetchAndBuildOptionsFor(target.value);
      });
    });

    const selectedOption = this.detailsTarget.selectedOptions[0].value;

    // Get the detailed purposes for the selected overview
    this.fetchAndBuildOptionsFor(selectedOverview, selectedOption);
  }

  addDetailsOptions({ options, selectedOption }) {
    this.detailsTarget.innerHTML = '<option value="">Please select one option</option>';

    options.forEach(({ name, id }) => {
      const isSelected = parseInt(id) === parseInt(selectedOption);

      const newOption = `
        <option value="${id}" ${isSelected ? 'selected' : ''}>
          ${name}
        </option>
      `;

      this.detailsTarget.insertAdjacentHTML('beforeend', newOption);
    });

    this.detailsTarget.removeAttribute('disabled');
  }

  fetchAndBuildOptionsFor(overviewId, selectedOption = null) {
    this.detailsTarget.setAttribute('disabled', true);

    axios.get(`/proceeds_purpose_overviews/${overviewId}`).then((response) => {
      this.addDetailsOptions({
        options: response.data.enabled_sorted_proceeds_purposes,
        selectedOption,
      });
    });
  }
}

import { Controller } from '@hotwired/stimulus';

// Controller for password change in the account page
export default class extends Controller {
  static targets = ['link', 'fields'];

  initialize() {
    this.open = false;
  }

  // Clears all the password inputs so if the users show and then hide the password fields they
  // don't submit password fields by mistake.
  clearFields() {
    const fields = this.fieldsTarget.querySelectorAll('input');

    fields.forEach((field) => (field.value = null));
  }

  toggle(event) {
    event.preventDefault();

    this.open = !this.open;

    if (!this.open) {
      this.fieldsTarget.classList.add('d-none');
      this.clearFields();
    } else {
      this.fieldsTarget.classList.remove('d-none');
    }

    return false;
  }
}

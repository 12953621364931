import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

const FILE_TYPES = ['NEWINC', 'AA', 'CS01'];

export default class extends Controller {
  static targets = ['newinc', 'aa', 'cs01'];
  static values = {
    scope: String
  }

  connect() {
    this.polledTimes = 0; // how many times did we try to fetch files from the server?
    this.applicationID = this.element.dataset.faId;

    // Make a first fetch. If the files are still being processed then start polling for updates.
    this.fetchBackendFiles((info) => {
      if (info && info.processing) {
        this.updatePolling = setInterval(() => {
          this.pollBackend();
        }, 3000);
      }
    });

    // Map types to the corresponding controller targets.
    this.targetMap = {
      NEWINC: this.newincTarget,
      AA: this.aaTarget,
      CS01: this.cs01Target,
    };
  }

  fetchBackendFiles(callback) {
    const prefix = this.scopeValue !== '' ? this.scopeValue + '/' : ''
    const url = `/${prefix}finance_applications/${this.applicationID}/companies_house_status`;

    return axios.get(url).then((response) => {
      this.updateUI(response.data);
      callback(response.data.info);
    });
  }

  pollBackend() {
    if (this.polledTimes > 5) {
      this.updateUI({
        info: { processing: false },
        attached_files: [],
        error: 'timeout',
      });
      clearInterval(this.updatePolling);
      return;
    }

    this.fetchBackendFiles((info) => {
      if (info && !info.processing) {
        clearInterval(this.updatePolling);
      }
    });

    this.polledTimes++;
  }

  // Because we only have 'CS01' div container we need to convert 'AR01' to 'CS01'.
  convertTypes(files) {
    return files.map((file) => {
      if (file.type == 'AR01') {
        file.type = 'CS01';
      }

      return file;
    });
  }

  // Returns the attached files for a particular Companies House type.
  attachedFiles({ attached_files }, type) {
    if (!attached_files) {
      return [];
    }

    return attached_files.filter((file) => file.type == type);
  }

  updateFileList(files, target) {
    files.forEach(({ url, barcode, name }) => {
      const link = `<p><a href="${url}" target="_blank" id="${barcode}">${name}</a></p>`;

      if (!document.getElementById(name)) {
        target.querySelector('[data-js="content"]').insertAdjacentHTML('beforeend', link);
      }
    });
  }

  updateUI({ info, attached_files }) {
    const { in_queue } = info;

    const files = this.convertTypes(attached_files);

    if (!in_queue) {
      return false;
    }

    FILE_TYPES.forEach((type) => {
      const filteredFiles = files.filter((file) => file.type == type);
      const count = info[type] || 0;
      const target = this.targetMap[type]; // get one of the target elements

      this.updateFileList(filteredFiles, target);

      if (filteredFiles && count == filteredFiles.length) {
        target.classList.remove('loading');
        // target.classList.add('success');
      }

      if (count == 0) {
        // target.classList.remove('success');
        // target.classList.add('warning');

        const message = '<p class="text-danger">Could not fetch any documents!</p>';

        target.querySelector('[data-js="content"]').insertAdjacentHTML('beforeend', message);
      }
    });
  }
}

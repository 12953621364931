import { Controller } from '@hotwired/stimulus';

// Controller for the BCA filter "tabs" on mobile. It's not really like tabs since no tab is open
// by default and clicking on the tab again closes the panel, but is close enough :)
//
// The index variable will hold the current active tab+panel and we relly on the index of the
// elements in the DOM.
export default class extends Controller {
  static targets = ['tab', 'panel'];

  initialize() {
    this.index = -1; // no panel open by default
  }

  change(event) {
    event.preventDefault();

    const { target } = event;

    // If the target is something inside the <a> we need to get the index of the parent
    const clickedTab = target.tagName == 'A' ? target : target.parentNode;
    const clickedIndex = this.tabTargets.indexOf(clickedTab);

    // When the user clicks the active tab we close the open panel.
    this.index = this.index == clickedIndex ? -1 : clickedIndex;
    this.showPanel();
  }

  close(event) {
    event.preventDefault();

    this.index = -1;
    this.showPanel();
  }

  showPanel() {
    this.tabTargets.forEach((tab, index) => {
      if (index == this.index) {
        tab.classList.add('is-active');
      } else {
        tab.classList.remove('is-active');
      }
    });

    this.panelTargets.forEach((panel, index) => {
      if (index == this.index) {
        panel.classList.remove('d-none');
      } else {
        panel.classList.add('d-none');
      }
    });
  }
}

import { Controller } from '@hotwired/stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    smoothscroll.polyfill();

    window.onscroll = () => {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        this.buttonTarget.classList.remove('d-none');
      } else {
        this.buttonTarget.classList.add('d-none');
      }
    };
  }

  backToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

import * as Ladda from 'ladda';
import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

/**
 * Creditsafe company search
 */
export default class extends Controller {
  static values = { failure: String, notFound: String, includeBlank: String };
  static targets = [
    'submit',
    'name',
    'number',
    'results',
    'companies',
    'filters',
    'inlineButtons',
    'mobileButton',
  ];

  connect() {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    this.laddaSubmit = Ladda.create(this.submitTarget);
  }

  startSearch() {
    this.laddaSubmit.start();
    this.nameTarget.setAttribute('disabled', 'disabled');
    this.numberTarget.setAttribute('disabled', 'disabled');
    this.resultsTarget.classList.remove('d-none');
  }

  stopSearch() {
    this.laddaSubmit.stop();
    this.nameTarget.removeAttribute('disabled');
    this.numberTarget.removeAttribute('disabled');
  }

  search(event) {
    this.startSearch();

    event.preventDefault();

    axios
      .post('/companies_house/lookup', this.formData())
      .then((response) => {
        this.renderResults(response.data);
        this.stopSearch();
      })
      .catch(() => {
        this.showError({ title: 'Unexpected error', message: this.notFoundValue });
        this.stopSearch();
      });
  }

  formData() {
    return {
      company_name: this.nameTarget.value,
      company_number: this.numberTarget.value,
    };
  }

  renderResults({ companies }) {
    const template = require('../templates/companies_house/companies.hbs');

    if (companies && companies.length > 0) {
      this.resultsTarget.innerHTML = template(
        { companies, includeBlank: this.includeBlankValue },
        { allowProtoPropertiesByDefault: true }
      );
    } else {
      this.showError({ title: '', message: this.notFoundValue });
    }
  }

  showError({ title, message }) {
    const template = require('../templates/companies_house/error.hbs');

    this.resultsTarget.innerHTML = '';
    this.resultsTarget.classList.remove('overlayed');
    this.resultsTarget.innerHTML = template(
      { title, message },
      { allowProtoPropertiesByDefault: true }
    );
  }

  filterResults(event) {
    const { target } = event;
    const { type } = target.dataset;

    event.preventDefault();

    // Add pill class to the right filter pill link
    this.filtersTargets.forEach((pill) => pill.classList.remove('active'));
    event.target.classList.add('active');

    // Hide all companies
    document.querySelectorAll('.companies-house-company').forEach((company) => {
      company.classList.add('d-none');
    });

    const filter = this._filterSelector(type);

    const filtered = document.querySelectorAll(`.companies-house-company${filter}`);
    filtered.forEach((company) => {
      company.classList.remove('d-none');
    });

    // Update the counter
    document.querySelector('span.results-size').innerHTML = filtered.length;
  }

  highLightCompany(event) {
    const { currentTarget } = event;

    // Hide all buttons
    this.inlineButtonsTargets.forEach((button) => {
      button.classList.remove('d-md-block');
    });

    // Select the radio button
    currentTarget.querySelector('input[type="radio"]').checked = true;

    // Enable the inline button and the big mobile button
    currentTarget.querySelector('.ladda-button').classList.add('d-md-block');
    this.mobileButtonTarget.disabled = false;
  }

  _filterSelector(type) {
    if (!type) { return '' }

    if (type == 'ltd') {
      return '[data-type="ltd"]'
    } else if (type == 'non-ltd') {
      return '[data-type]:not([data-type="ltd"])'
    } else {
      return ''
    }
  }
}

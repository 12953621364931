import { Controller } from '@hotwired/stimulus';

/**
 * Company lookup radio buttons
 */
export default class extends Controller {
  static values = { selected: Number, lookupOn: Array, lookupMethod: String };
  static targets = ['method', 'existingCompany', 'newCompany', 'companiesHouseForm', 'creditsafeForm', 'manualForm'];

  connect() {
    if (this.hasExistingCompanyTarget) {
      this.useExistingCompany();
    }
  }

  hideAllForms() {
    if (this.hasExistingCompanyTarget) {
      this.existingCompanyTarget.classList.add('d-none');
    }

    this.manualFormTarget.classList.add('d-none');
    this.companiesHouseFormTarget.classList.add('d-none');
    if (this.hasCreditsafeFormTarget) {
      this.creditsafeFormTarget.classList.add('d-none');
    }
  }

  changeCompanyType(event) {
    const {
      target: { value },
    } = event;

    if (!value) {
      this.hideAllForms();
      return;
    }

    if (this.lookupOnValue.includes(value)) {
      this.useLookupMethod()
    } else {
      this.useManual();
    }
  }

  skip() {
    this.methodTarget.value = 'manual';
  }

  useLookupMethod() {
    if (this.lookupMethodValue == 'creditsafe') {
      this.useCreditSafe()
    } else if (this.lookupMethodValue == 'companies_house') {
      this.useCompaniesHouse()
    } else {
      this.useManual()
    }
  }

  useNewCompany() {
    this.hideAllForms();
    this.newCompanyTarget.classList.remove('d-none');
    this.methodTarget.classList.remove('d-none');
  }

  useExistingCompany() {
    this.hideAllForms();
    this.methodTarget.value = 'existing';
    this.existingCompanyTarget.classList.remove('d-none');
    this.newCompanyTarget.classList.add('d-none');
  }

  useCompaniesHouse() {
    this.hideAllForms();
    this.methodTarget.value = 'companies_house';
    this.companiesHouseFormTarget.classList.remove('d-none');
  }

  useCreditSafe() {
    this.hideAllForms();
    this.methodTarget.value = 'creditsafe';
    this.creditsafeFormTarget.classList.remove('d-none');
  }

  useManual() {
    this.hideAllForms();
    this.methodTarget.value = 'manual';
    this.manualFormTarget.classList.remove('d-none');
  }
}

import * as bootstrap from 'bootstrap';

const getPopoverTriggers = () => {
  return [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
};

// Enable dismiss when clicking outside of the popover
const dismissablePopovers = () => {
  document.addEventListener('mouseup', (event) => {
    const { target } = event;
    const popovers = getPopoverTriggers();
    const outside = target.className.indexOf('popover') == -1;
    const onTrigger = popovers.find((popover) => {
      return popover.contains(target);
    });

    if (outside && !onTrigger) {
      popovers.forEach((popover) => {
        const instance = bootstrap.Popover.getInstance(popover);
        instance.hide();
      });
    }

    if (onTrigger) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
};

export const triggerBootstrapPopovers = () => {
  const popoverTriggerList = getPopoverTriggers();

  popoverTriggerList.map((trigger) => new bootstrap.Popover(trigger, { html: true }));
  dismissablePopovers();
};

import { Controller } from '@hotwired/stimulus';
import { Popover } from 'bootstrap';

// Enables soft validation of input elements
// with informational popover which doesn't prevent form submit
// Example usage:
// <input data-controller="soft-validation"
//        data-soft-validation-validator-value="greaterThanOrEqual"
//        data-soft-validation-arguments-value="[10000.0]"
//        data-bs-placement="right"
//        data-bs-content="Requested amount (£10,000.00) is larger than last year turnover"
//        type="text"
//        name="company_details[company_turnover]">
export default class extends Controller {
  static values = {
    validator: String,
    arguments: Array,
  };

  connect() {
    this.popover = new Popover(this.element, { html: true, trigger: 'manual' });

    this[this.validatorValue].apply(this, this.argumentsValue);
    this.element.addEventListener('keyup', () => {
      this[this.validatorValue].apply(this, this.argumentsValue);
    });
  }

  // Validate that element value is greater than or equal to provided argument
  greaterThanOrEqual(argument) {
    const currentValue = this.element.value;

    this.apply(currentValue === '' || Number(currentValue) >= Number(argument));
  }

  apply(validateCondition) {
    if (validateCondition) {
      this.popover.hide();
    } else {
      this.popover.show();
    }
  }
}

import { Controller } from '@hotwired/stimulus';

// Controller for Modals support
export default class extends Controller {
  static targets = ['input'];

  toggle(event) {
    const { target } = event;

    this.inputTargets.forEach((input) => {
      input.checked = target.checked;
    });
  }
}

import { Controller } from '@hotwired/stimulus';

/**
 * Example usage:
 * <a href="#" data-controller="cancel-button" data-cancel-text="Cancel connection">
 *   <span class="badge">2</span>
 *   <span data-cancel-button-target="text">In progress</span>
 * </a>
 */
export default class extends Controller {
  static targets = ['text'];

  initialize() {
    this.textBeforeHover = this.textTarget.innerText;

    if (!this.element.classList.contains('btn-secondary')) {
      this.element.classList.add('btn-secondary');
    }

    this.element.addEventListener('mouseover', () => {
      this.textTarget.innerText = this.element.dataset.cancelText;
      this.element.classList.remove('btn-secondary');
      this.element.classList.add('btn-danger');
    });

    this.element.addEventListener('mouseout', () => {
      this.textTarget.innerText = this.textBeforeHover;
      this.element.classList.add('btn-secondary');
      this.element.classList.remove('btn-danger');
    });
  }
}

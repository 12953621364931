import { Controller } from '@hotwired/stimulus';

// Controller for expanding the product info
export default class extends Controller {
  static targets = ['text', 'toggler'];

  connect() {
    // Hide the "toggler" if not needed
    // When opened in modal, clientHeight is 0
    // so we will always show toggler in that case
    if (this.textTarget.clientHeight > 0 && this.textTarget.clientHeight < 60) {
      this.togglerTarget.classList.add('visually-hidden');
    }
  }

  triggerToggler() {
    if (this.togglerTarget.dataset.visible) {
      this.togglerTarget.innerText = 'Show full profile';
      delete this.togglerTarget.dataset.visible;
    } else {
      this.togglerTarget.innerText = 'Hide full profile';
      this.togglerTarget.dataset.visible = true;
    }
  }
}

import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

export default class extends Controller {
  increment() {
    const { target } = event;

    const data = { product: target.dataset.product, option: target.dataset.option };

    const token = document.querySelector('meta[name="csrf-token"]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    axios.post(target.dataset.url, data);
  }
}

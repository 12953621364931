import { Controller } from '@hotwired/stimulus';
import 'parsleyjs';

/**
 * Example usage:
 * <form data-controller="parsley">
 *   <input type="submit" data-parsley-target="parsley-submit" />
 * </form>
 */
export default class extends Controller {
  static targets = ['submit'];

  initialize() {
    this.submitTarget.addEventListener('click', (event) => {
      $(this.element)
        .parsley()
        .on('form:error', () => {
          event.preventDefault();
        });
    });
  }
}

import Handlebars from 'handlebars';

const pluralize = (number, one, many) => {
  one = Handlebars.Utils.escapeExpression(one);
  many = Handlebars.Utils.escapeExpression(many);

  const pluralized = number == 1 ? one : many;

  const result = '<span>' + pluralized + '</span>';

  return new Handlebars.SafeString(result);
};

export default pluralize;

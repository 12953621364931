import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['toggle'];

  connect() {
    this.mainDiv = document.getElementById('main-content');
    this.sidebarDiv = document.getElementById('sidebar');

    this.toggleTarget.addEventListener('click', (event) => {
      event.preventDefault();
      this.toggleSidebar();
    });
  }

  toggleSidebar() {
    this.mainDiv.classList.toggle('sidebar-open');
    this.sidebarDiv.classList.toggle('sidebar-open');

    this.storePreference();
  }

  storePreference() {
    const COOKIE_NAME = this.sidebarDiv.dataset.cookieName;
    Cookies.set(COOKIE_NAME, this.mainDiv.classList.contains('sidebar-open'));
  }
}

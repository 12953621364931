var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./_company.hbs"),depth0,{"name":"_company","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./_company.hbs"),depth0,{"name":"_company","hash":{"number":"Select this option if you are not able to find your company","name":"None of the above","id":(depth0 != null ? lookupProperty(depth0,"includeBlank") : depth0),"class":"radio-none"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mt-3\">\n  <h1>Matched companies</h1>\n\n  <div class=\"d-flex align-items-md-center justify-content-between flex-column flex-md-row\">\n    <p>\n      We found <strong><span class=\"results-size\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"companies") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span></strong>\n      "
    + alias1(__default(require("../helpers/pluralize.js")).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"companies") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"company","companies",{"name":"pluralize","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":58}}}))
    + " matching your query.\n    </p>\n\n    <ul class=\"nav nav-pills mb-3\">\n      <li class=\"nav-item\">\n        <a class=\"nav-link active\" href=\"#\" aria-current=\"page\" data-companies-house-target=\"filters\" data-action=\"companies-house#filterResults\">\n          All\n        </a>\n      </li>\n\n      <li class=\"nav-item\">\n        <a class=\"nav-link\" href=\"#\" data-companies-house-target=\"filters\" data-action=\"companies-house#filterResults\" data-type=\"ltd\">\n          Ltd\n        </a>\n      </li>\n\n      <li class=\"nav-item\">\n        <a class=\"nav-link\" href=\"#\" data-companies-house-target=\"filters\" data-action=\"companies-house#filterResults\" data-type=\"non-ltd\">\n          NonLtd\n        </a>\n      </li>\n    </ul>\n  </div>\n\n  <div class=\"radios-companies\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"companies") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"includeBlank") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n  <!-- Mobile next button -->\n  <div class=\"fixed-bottom py-3 bg-light d-md-none\">\n    <div class=\"container\">\n      <div class=\"row\">\n        <div class=\"col-md-12 d-flex flex-column\">\n          <button class=\"ladda-button\" data-style=\"slide-right\"\n            data-size=\"sm\" data-disable-with=\"Please wait...\"\n            data-companies-house-target=\"mobileButton\"\n            disabled>\n            <span class=\"ladda-label\">Next →</span>\n          </button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
import { Controller } from '@hotwired/stimulus';

/**
 * Example usage:
 * <input data-controller="submit-on-change" />
 * or:
 * <select data-controller="submit-on-change" />
 */
export default class extends Controller {
  initialize() {
    this.element.addEventListener('change', () => {
      this.element.closest('form').submit();
    });
  }
}

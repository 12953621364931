import { Controller } from '@hotwired/stimulus';

/**
 * Example usage:
 * <div data-controller="fast-connection">
 *   <input type="checkbox" data-fast-connection-target="consent" />
 *   <button data-fast-connection-target="submit">Submit</button>
 * </div>
 */
export default class extends Controller {
  static targets = ['consent', 'submit'];

  connect() {
    this.consentTarget.addEventListener('change', () => {
      const isDisabled = this.submitTarget.getAttribute('disabled') === 'disabled';

      if (isDisabled) {
        this.submitTarget.removeAttribute('disabled');
      } else {
        this.submitTarget.setAttribute('disabled', 'disabled');
      }
    });
  }
}

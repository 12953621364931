import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'clear'];
  static classes = ['hide'];

  connect() {
    this.toggle();
  }

  toggle() {
    const visible = Boolean(this.inputTarget.value);

    if (visible) {
      this.clearTarget.classList.remove(this.hideClass);
    } else {
      this.clearTarget.classList.add(this.hideClass);
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['extraFields', 'relationship'];

  connect() {
    this.relationship = this.relationshipTarget.value;
    this.relationsWithExtrafields = this.relationshipTarget.dataset.relationshipsWithExtraFields;
    this.toggleExtraFields();
  }

  toggleExtraFields(event) {
    if (event) {
      const { value } = event.currentTarget;
      this.relationship = value;
    }

    if (this.shouldShowExtraFields()) {
      this.extraFieldsTarget.classList.remove('d-none');
    } else {
      this.extraFieldsTarget.classList.add('d-none');
    }
  }

  shouldShowExtraFields() {
    return this.relationsWithExtrafields.includes(this.relationship);
  }
}
